import React, { useEffect, useRef, useState } from "react"
import { Form, Input, Select } from "antd"
import "./editable-cell.css"

const { Option } = Select
const EditableContext = React.createContext()

const EditableCell = ({
  requiredMessage,
  editable,
  children,
  dataIndex,
  record,
  onSave,
  provider,
  form,
  widthCell,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const [value, setValue] = useState({ ...record })

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  if (!editable) {
    return <td {...restProps}>{children}</td>
  }

  const toggleEdit = () => {
    setEditing(!editing)
  }

  const handleInputChange = event => {
    setValue({
      ...value,
      [dataIndex]: event.target.value,
    })
  }

  const handleSelectChange = selected => {
    const stateValue = {
      ...value,
      [dataIndex]: selected,
    }
    setValue(stateValue)
    toggleEdit()
    onSave(stateValue)
  }

  const save = () => {
    toggleEdit()
    onSave(value)
  }

  let selectedValue = value[dataIndex]
  if (provider && provider.length > 0 && selectedValue) {
    const selectedInProvider = provider.find(
      p => p.value.toUpperCase() === value[dataIndex].toUpperCase()
    )
    selectedValue = selectedInProvider ? selectedInProvider.value : ""
    if (!selectedInProvider) {
      const stateValue = {
        ...value,
        [dataIndex]: "",
      }
      setValue(stateValue)
      onSave(stateValue)
    }
  }
  let selectedValueToShow = selectedValue
  let dataTitle
  const widthChar = 7
  if (
    widthCell &&
    selectedValue &&
    selectedValue.length * widthChar > widthCell
  ) {
    selectedValueToShow = `${selectedValue.substring(
      0,
      Math.floor(widthCell / widthChar)
    )}...`
    dataTitle = selectedValue
  }

  const getEditingComponent = () => {
    if (provider && provider.length > 0) {
      return (
        <Select
          ref={inputRef}
          name={dataIndex}
          onChange={handleSelectChange}
          style={{ width: "100%" }}
          onBlur={save}
          value={selectedValue}
          aria-label={dataIndex}
        >
          {provider.map(p => (
            <Option key={p.value} value={p.value}>
              {p.label}
            </Option>
          ))}
        </Select>
      )
    }

    return (
      <>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: requiredMessage,
            },
          ],
          initialValue: selectedValue,
        })(
          <Input
            aria-label={dataIndex}
            ref={inputRef}
            name={dataIndex}
            onPressEnter={save}
            onBlur={save}
            onChange={handleInputChange}
          />
        )}
      </>
    )
  }

  const renderCell = () => {
    if (editing) {
      return (
        <Form.Item style={{ margin: 0 }}>{getEditingComponent()}</Form.Item>
      )
    }

    return (
      <div
        className="editable-cell-value"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
        data-title={dataTitle}
      >
        {selectedValueToShow}
      </div>
    )
  }

  return (
    <td {...restProps}>
      <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
    </td>
  )
}

export default Form.create()(EditableCell)
