import moment from "moment-timezone"
import { message } from "antd"
import authFetch from "../../services/network"
import { navigate } from "gatsby-plugin-intl"

const cloneDictionary = dictionary => {
  return {
    id: "",
    name: dictionary.name,
    description: dictionary.description,
    fields: dictionary.fields.slice(),
  }
}

const initEmptyDictionary = () => {
  return {
    name: "",
    version: "",
    description: "",
    fields: [],
  }
}

const assemblyResponse = dictionary => {
  const { name, version, description, fields } = dictionary
  return {
    name,
    version,
    description,
    fields: fields.map(f => {
      if (f.property === "") {
        f.property = "none"
      }
      return f
    }),
  }
}

const getDictionaryDetailFormat = (dictionary, versions) => {
  dictionary.createdAt = moment().format("DD/MM/YYYY HH:mm")
  dictionary.id = `${dictionary.name}:${dictionary.version}`
  versions.push(dictionary)
  return {
    id: dictionary.id,
    name: dictionary.name,
    description: dictionary.description,
    createdAt: dictionary.createdAt,
    fields: versions.length > 0 ? versions[versions.length - 1].fields : [],
    countVersions: versions.length,
    versions: versions,
  }
}

const displayError = ({ errorCode = "", intl }) => {
  if (errorCode === "DICT_WRITE_ERROR_VERSION_ALREADY_EXISTS") {
    message.error(
      intl.formatMessage({ id: "dicitonary-version-ko-already-exists" })
    )
  } else {
    message.error(intl.formatMessage({ id: "dictionary-save-error-text" }))
  }
}

const handleSubmit = ({ dictionary, setState, state, intl }) => {
  setState({ buttonsLoading: true })
  authFetch(`${process.env.GATSBY_CONF_API_URL}/dictionaries/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: assemblyResponse(dictionary),
  })
    .then(response => {
      if (response.status === 201) {
        message.success(
          state.isVersionForm
            ? intl.formatMessage({
                id: "dictionary-version-create-ok-text-alert",
              })
            : intl.formatMessage({ id: "dictionary-create-ok-text-alert" })
        )
        const newDictToDetail = getDictionaryDetailFormat(
          dictionary,
          state.versions
        )
        navigate("/dictionary/detail", {
          state: { dictionary: newDictToDetail },
        })
      } else if (response.status === 400) {
        response.json().then(({ message = "" }) => {
          displayError({ errorCode: message, intl })
        })
      }
    })
    .catch(() => displayError({ intl }))
    .finally(() => setState({ buttonsLoading: false }))
}

const cancelForm = ({ state }) => () => {
  if (state.isVersionForm) {
    window && window.history && window.history.back && window.history.back()
  } else {
    navigate("/dictionary")
  }
}

const formUtils = {
  cloneDictionary,
  initEmptyDictionary,
  handleSubmit,
  cancelForm,
}

export default formUtils
