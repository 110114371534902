import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { v4 as uuidV4 } from "uuid"

const MessageErrors = ({ errors, onClose, intl }) => (
  <div aria-label="message-errors">
    {errors.map(error => (
      <div key={uuidV4()}>{error}</div>
    ))}
    <Button style={{ marginTop: 10 }} type="primary" onClick={onClose}>
      {intl.formatMessage({ id: "close" })}
    </Button>
  </div>
)

MessageErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
}

export default MessageErrors
