import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd"
import { useIntl } from "gatsby-plugin-intl"
import useStateObject from "../../hooks/useStateObject"
import EditableCell from "./editable-cell"
import utils from "./utils"
import { v4 as uuidV4 } from "uuid"

const { Option } = Select
const { TextArea } = Input
const EditableContext = React.createContext()

const NewDictionaryForm = ({
  dictionary,
  isVersionForm,
  onSubmitForm,
  onCancel,
  getFieldDecorator,
  buttonsLoading,
}) => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    dictionary: {
      ...dictionary,
      fields: dictionary.fields.map(field => ({ ...field, key: uuidV4() })),
      version: "",
    },
    current: {
      name: "",
      description: "",
      additionalInfo: "",
      type: "",
      property: "",
    },
    ui: {
      propertyOptions: utils.PROPERTY_OPTIONS,
      dictTypes: [],
    },
    isVersionForm,
  })

  const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  )
  const EditableFormRow = Form.create()(EditableRow)

  return (
    <Form layout="horizontal">
      <Row type="flex">
        <Col span={24}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <h3 style={{ marginRight: "10px" }}>
              {intl.formatMessage({ id: "basic-information" })}
            </h3>
          </div>
        </Col>
      </Row>
      <Row type="flex">
        <Row type="flex" style={{ width: "30%" }}>
          <Col span={24}>
            <Form.Item
              label={
                state.isVersionForm
                  ? intl.formatMessage({
                      id: "dictionary-form-name",
                    })
                  : intl.formatMessage({
                      id: "dictionary-form-new-name",
                    })
              }
            >
              {getFieldDecorator("name", {
                initialValue: state.dictionary.name,
                rules: [
                  {
                    required: !state.isVersionForm,
                    message: intl.formatMessage({
                      id: "dictionary-form-rule-no-name-error-text",
                    }),
                  },
                  {
                    validator: (rule, value, cb) => {
                      try {
                        if (/\s/.test(value)) {
                          throw new Error(
                            intl.formatMessage({
                              id:
                                "dictionary-form-rule-no-whitespaces-error-text",
                            })
                          )
                        } else {
                          cb()
                        }
                      } catch (err) {
                        cb(err)
                      }
                    },
                  },
                ],
              })(
                <Input
                  name="name"
                  aria-label="dictionary-name"
                  onChange={utils.handleChange({ state, setState })}
                  disabled={state.isVersionForm}
                  validatestatus="error"
                  placeholder={intl.formatMessage({
                    id: "dictionary-form-name-placeholder",
                  })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({
                id: "dictionary-version-form-name",
              })}
            >
              {getFieldDecorator("version", {
                initialValue: state.dictionary.version,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "dictionary-version-form-rule-no-name-error-text",
                    }),
                  },
                  {
                    validator: (rule, value, cb) => {
                      try {
                        if (/\s/.test(value)) {
                          throw new Error(
                            intl.formatMessage({
                              id:
                                "dictionary-form-rule-no-whitespaces-error-text",
                            })
                          )
                        } else {
                          cb()
                        }
                      } catch (err) {
                        cb(err)
                      }
                    },
                  },
                ],
              })(
                <Input
                  name="version"
                  aria-label="dictionary-version"
                  onChange={utils.handleChange({ state, setState })}
                  validatestatus="error"
                  placeholder={
                    state.isVersionForm
                      ? intl.formatMessage({
                          id: "dictionary-version-form-new-name",
                        })
                      : intl.formatMessage({
                          id: "dictionary-version-form-name-placeholder",
                        })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" style={{ width: "30%", paddingLeft: "60px" }}>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({
                id: "dictionary-version-form-description",
              })}
              layout="horizontal"
            >
              {getFieldDecorator("description", {
                initialValue: state.dictionary.description,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "dictionary-form-rule-no-description-error-text",
                    }),
                  },
                ],
              })(
                <TextArea
                  name="description"
                  aria-label="dictionary-description"
                  onChange={utils.handleChange({ state, setState })}
                  placeholder={
                    state.isVersionForm
                      ? intl.formatMessage({
                          id: "dictionary-version-form-description-placeholder",
                        })
                      : intl.formatMessage({
                          id: "dictionary-form-description-placeholder",
                        })
                  }
                  style={{ minHeight: "10em" }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <h3 style={{ marginRight: "10px" }}>
              {intl.formatMessage({ id: "fields" })}
            </h3>
            <Tooltip
              title={intl.formatMessage({
                id: "dictionary-form-rule-2-fields-error-text",
              })}
            >
              <Icon type="info-circle" size="large" />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row type="flex">
        <Row type="flex" style={{ width: "30%" }}>
          <Col span={24}>
            <Form.Item
              layout="horizontal"
              label={intl.formatMessage({
                id: "dictionary-form-field-name",
              })}
            >
              <Input
                name="name"
                aria-label="field-name"
                value={state.current.name}
                onChange={utils.handleCurrentChange({ state, setState })}
                placeholder={intl.formatMessage({
                  id: "dictionary-form-field-name-placeholder",
                })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              layout="horizontal"
              label={intl.formatMessage({ id: "property" })}
            >
              <Select
                name="property"
                aria-label="field-property"
                defaultValue="property:none"
                value={state.current.property}
                onChange={utils.handleSelectChange({ state, setState })}
                style={{ width: "100%" }}
              >
                {state.ui.propertyOptions.map(p => (
                  <Option key={p.value} value={p.value}>
                    {p.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              layout="horizontal"
              label={intl.formatMessage({ id: "type" })}
            >
              <Select
                name="type"
                aria-label="field-type"
                value={state.current.type}
                onChange={utils.handleSelectChange({ state, setState })}
                style={{ width: "100%" }}
                disabled={state.current.property === "ts_index"}
              >
                {(state.ui.dictTypes || []).map(type => (
                  <Option key={"type:" + type} value={"type:" + type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" style={{ width: "30%", paddingLeft: "60px" }}>
          <Col span={24}>
            <Form.Item
              layout="horizontal"
              label={intl.formatMessage({
                id: "dictionary-form-field-description",
              })}
            >
              <Input
                name="description"
                aria-label="field-description"
                value={state.current.description}
                onChange={utils.handleCurrentChange({ state, setState })}
                placeholder={intl.formatMessage({
                  id: "dictionary-form-field-description-placeholder",
                })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({
                id: "dictionary-version-form-additional-info",
              })}
              layout="horizontal"
            >
              <TextArea
                name="additionalInfo"
                onChange={utils.handleCurrentChange({ state, setState })}
                placeholder={intl.formatMessage({
                  id: "dictionary-version-form-additional-info-placeholder",
                })}
                style={{ minHeight: "10em" }}
                value={state.current.additionalInfo}
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>
      <Row type="flex">
        <Row type="flex" style={{ width: "30%" }}>
          <Col>
            <Tag
              type="primary"
              aria-label="field-append-button"
              onClick={utils.handleAppend({ state, intl, setState })}
            >
              <Icon type="plus" />
              &nbsp;
              {intl.formatMessage({
                id: "dictionary-form-field-add",
              })}
            </Tag>
          </Col>
          <Col>
            <input
              type="file"
              id="uploadDictionaryFile"
              onChange={utils.readDictionaryFile({ state, setState, intl })}
              style={{ display: "none" }}
              accept="application/json"
              multiple={false}
            />
            <Tag type="primary" onClick={utils.handleUploadDictionary}>
              <Icon type="upload" />
              &nbsp;
              {intl.formatMessage({
                id: "dictionary-form-upload",
              })}
            </Tag>
          </Col>
        </Row>
        <Row type="flex" style={{ width: "30%" }}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Tag
              type="primary"
              onClick={utils.handleDownloadTemplate({ intl })}
            >
              <Icon type="download" />
              &nbsp;
              {intl.formatMessage({
                id: "dictionary-form-download-template",
              })}
            </Tag>
          </Col>
        </Row>
      </Row>
      <Row type="flex" align="top" style={{ marginTop: 36 }}>
        <Col span={24}>
          <Table
            size="small"
            rowKey="name"
            columns={utils.fieldsTableColumns({ intl, state, setState })}
            dataSource={state.dictionary.fields}
            locale={{
              filterTitle: intl.formatMessage({ id: "filters" }),
              filterConfirm: intl.formatMessage({ id: "apply" }),
              filterReset: intl.formatMessage({ id: "reset" }),
              emptyText: intl.formatMessage({ id: "no-data" }),
            }}
            components={{
              body: {
                row: EditableFormRow,
                cell: EditableCell,
              },
            }}
            rowClassName={() => "editable-row"}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Col span={12} style={{ display: "flex" }}>
          <Form.Item style={{ marginTop: 10 }}>
            <Button
              type="primary"
              aria-label="create-dictionary-button"
              htmlType="submit"
              loading={buttonsLoading}
              onClick={utils.handleSubmitEmitter({ onSubmitForm, state, intl })}
            >
              {state.isVersionForm
                ? intl.formatMessage({
                    id: "dictionary-version-form-create-text-button",
                  })
                : intl.formatMessage({
                    id: "dictionary-form-create-text-button",
                  })}
            </Button>
          </Form.Item>
          <Form.Item style={{ marginTop: 10, marginLeft: 15 }}>
            <Button type="secondary" htmlType="submit" onClick={onCancel}>
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

NewDictionaryForm.propTypes = {
  dictionary: PropTypes.object.isRequired,
  isVersionForm: PropTypes.bool,
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  buttonsLoading: PropTypes.bool.isRequired,
}

export default NewDictionaryForm
