import { Button, message } from "antd"
import MessageErrors from "./message-errors"
import React from "react"
import { v4 as uuidV4 } from "uuid"
import template from "./template.json"

const FIELD_PROPERTIES = ["TS_INDEX", "NONE", "METRIC"]
const FIELD_TYPES = ["DOUBLE", "STRING", "TIMESTAMP", "FLOAT", "LONG"]
const PROPERTY_OPTION_NULL = {
  label: "(none)",
  value: "property:none",
}
const PROPERTY_OPTION_TS_INDEX = {
  label: "ts_index",
  value: "property:ts_index",
}
const PROPERTY_OPTION_METRIC = {
  label: "metric",
  value: "property:metric",
}
const PROPERTY_OPTIONS = [
  PROPERTY_OPTION_NULL,
  PROPERTY_OPTION_TS_INDEX,
  PROPERTY_OPTION_METRIC,
]

const getFieldsErrors = ({ fields, intl }) => {
  const errors = []
  if (!fields || fields.length === 0) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-form-rule-2-fields-error-text",
      })
    )
  } else {
    const ruleTsIndex = fields.some(
      field => String(field.property).toLowerCase() === "ts_index"
    )
    if (!ruleTsIndex) {
      errors.push(
        intl.formatMessage({
          id: "dictionary-form-rule-no-ts_index-error-text",
        })
      )
    }
    const ruleAnotherField = fields.some(
      field => String(field.property).toLowerCase() !== "ts_index"
    )
    if (!ruleAnotherField) {
      errors.push(
        intl.formatMessage({
          id: "dictionary-form-rule-no-more-field-error-text",
        })
      )
    }
    fields.forEach(field => {
      const { name, description, property, type } = field
      if (!name || name.trim() === "") {
        errors.push(
          intl.formatMessage({
            id: "dictionary-fields-no-name-error-text",
          })
        )
      } else {
        if (name.length > 50) {
          errors.push(
            intl.formatMessage(
              { id: "dictionary-fields-too-large-name-error-text" },
              { value: name }
            )
          )
        }
        if (!description || description.trim() === "") {
          errors.push(
            intl.formatMessage({
              id: "dictionary-fields-no-description-error-text",
            })
          )
        } else if (description.length > 250) {
          errors.push(
            intl.formatMessage(
              { id: "dictionary-fields-too-large-description-error-text" },
              { value: name }
            )
          )
        }
        if (
          property &&
          FIELD_PROPERTIES.indexOf(property.trim().toUpperCase()) === -1
        ) {
          errors.push(
            intl.formatMessage(
              { id: "dictionary-fields-invalid-property-error-text" },
              { value: name }
            )
          )
        }
        if (!type || type.trim() === "") {
          errors.push(
            intl.formatMessage({
              id: "dictionary-fields-no-type-error-text",
            })
          )
        } else if (FIELD_TYPES.indexOf(type) === -1) {
          errors.push(
            intl.formatMessage(
              { id: "dictionary-fields-invalid-type-error-text" },
              { value: name }
            )
          )
        } else if (
          property &&
          property.trim().toUpperCase() === "TS_INDEX" &&
          type !== "TIMESTAMP"
        ) {
          errors.push(
            intl.formatMessage(
              { id: "dictionary-fields-invalid-type-property-error-text" },
              { value: name }
            )
          )
        }
      }
    })
  }
  return [...new Set(errors)]
}

const getErrors = ({ state, intl }) => {
  const errors = []
  const {
    dictionary: { name, version, description, fields },
  } = state
  if (!name) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-form-rule-no-name-error-text",
      })
    )
  }
  if (!version) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-version-form-rule-no-name-error-text",
      })
    )
  }
  if (!description) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-version-form-rule-no-description-error-text",
      })
    )
  } else if (description.length > 250) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-form-too-large-description-error-text",
      })
    )
  }
  if (
    (name || version) &&
    (name.split(" ").length - 1 > 0 || version.split(" ").length - 1 > 0)
  ) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-form-rule-no-whitespaces-error-text",
      })
    )
  }
  errors.push(...getFieldsErrors({ fields, intl }))
  return errors
}

const handleSubmitEmitter = ({ onSubmitForm, state, intl }) => () => {
  const errors = getErrors({ state, intl })
  if (errors.length > 0) {
    const hideMessage = message.error({
      content: (
        <MessageErrors
          errors={errors}
          onClose={() => hideMessage()}
          intl={intl}
        />
      ),
      duration: 0,
    })
  } else {
    // Removing "key" property from fields
    const fields = state.dictionary.fields.map(field => {
      const { key, ...rest } = field
      return rest
    })
    onSubmitForm({ ...state.dictionary, fields })
  }
}

const handleSave = ({ state, setState, row }) => {
  const fields = [...state.dictionary.fields]
  const index = fields.findIndex(item => row.key === item.key)
  const item = fields[index]
  fields.splice(index, 1, { ...item, ...row })
  setState({
    dictionary: {
      ...state.dictionary,
      fields,
    },
  })
}

const handleRemove = ({ record, setState, state, intl }) => {
  if (
    String(record.property).toLowerCase() ===
    PROPERTY_OPTION_TS_INDEX.label.toLowerCase()
  ) {
    setState({ ui: { propertyOptions: PROPERTY_OPTIONS } })
  }
  const newFields = state.dictionary.fields.filter(
    item => record.key !== item.key
  )
  message.success(intl.formatMessage({ id: "field-removed" }))
  const updateDict = state.dictionary
  updateDict.fields = newFields
  setState({ dictionary: updateDict })
}

const fieldsTableColumns = ({ intl, state, setState }) => {
  const browserWidth = window.innerWidth
    ? window.innerWidth
    : document.body.offsetWidth
  const nameColumnWidth = 400
  const propertyColumnWidth = 200
  const typeColumnWidth = 200
  const actionsColumnWidth = 100
  const restColumnWidth =
    browserWidth -
    nameColumnWidth -
    propertyColumnWidth -
    typeColumnWidth -
    actionsColumnWidth -
    100
  const descriptionColumnWidth = restColumnWidth / 2
  const additionalInfoColumnWidth = restColumnWidth / 2
  return [
    {
      title: intl.formatMessage({ id: "key" }),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ["ascend", "descend"],
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "name",
        requiredMessage: intl.formatMessage({
          id: "dictionary-fields-no-name-error-text",
        }),
        onSave: row => handleSave({ state, setState, row }),
        widthCell: nameColumnWidth,
      }),
      width: nameColumnWidth,
      align: "left",
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "description",
      key: "description",
      sorter: (a, b) =>
        a.description < b.description
          ? -1
          : a.description > b.description
          ? 1
          : 0,
      sortDirections: ["ascend", "descend"],
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "description",
        requiredMessage: intl.formatMessage({
          id: "dictionary-fields-no-description-error-text",
        }),
        onSave: row => handleSave({ state, setState, row }),
        widthCell: descriptionColumnWidth,
      }),
      align: "left",
      width: descriptionColumnWidth,
    },
    {
      title: intl.formatMessage({
        id: "dictionary-version-form-additional-info",
      }),
      dataIndex: "additionalInfo",
      key: "additionalInfo",
      sorter: (a, b) =>
        a.additionalInfo < b.additionalInfo
          ? -1
          : a.additionalInfo > b.additionalInfo
          ? 1
          : 0,
      sortDirections: ["ascend", "descend"],
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "additionalInfo",
        onSave: row => handleSave({ state, setState, row }),
        widthCell: additionalInfoColumnWidth,
      }),
      align: "left",
      width: additionalInfoColumnWidth,
    },
    {
      title: intl.formatMessage({ id: "property" }),
      dataIndex: "property",
      key: "property",
      sorter: (a, b) =>
        a.property < b.property ? -1 : a.property > b.property ? 1 : 0,
      sortDirections: ["ascend", "descend"],
      onCell: record => {
        return {
          record,
          editable: true,
          dataIndex: "property",
          provider: PROPERTY_OPTIONS.map(option => ({
            label: option.label,
            value: option.value.split(":")[1],
          })),
          onSave: row => handleSave({ state, setState, row }),
        }
      },
      width: propertyColumnWidth,
      align: "left",
    },
    {
      title: intl.formatMessage({ id: "type" }),
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => (a.type < b.type ? -1 : a.type > b.type ? 1 : 0),
      sortDirections: ["ascend", "descend"],
      onCell: record => {
        const name = record.property ? "property" : ""
        const types = getDictTypes(name, record.property)
        return {
          record,
          editable: true,
          dataIndex: "type",
          provider: types.map(type => ({ label: type, value: type })),
          onSave: row => handleSave({ state, setState, row }),
        }
      },
      width: typeColumnWidth,
      align: "left",
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "id",
      key: "id",
      width: actionsColumnWidth,
      align: "center",
      render: (text, record) => (
        <span>
          <Button
            type="link"
            onClick={() => handleRemove({ record, setState, state, intl })}
          >
            {intl.formatMessage({ id: "delete" })}
          </Button>
        </span>
      ),
    },
  ]
}

const handleChange = ({ state, setState }) => event => {
  const {
    target: { name, value },
  } = event
  state.dictionary[name] = value
  setState({ dictionary: state.dictionary })
}

const handleCurrentChange = ({ state, setState }) => event => {
  const {
    target: { name, value },
  } = event
  const { current } = state
  current[name] = value
  setState({ current })
}

const getDictTypes = (name, value) => {
  if (name === "property") {
    switch (value) {
      case "metric":
        return ["DOUBLE", "FLOAT", "LONG"]
      case "ts_index":
        return ["TIMESTAMP"]
      case "none":
        return ["DOUBLE", "FLOAT", "LONG", "STRING"]
      default:
        return []
    }
  } else {
    return ["DOUBLE", "FLOAT", "LONG", "STRING"]
  }
}

const handleSelectChange = ({ state, setState }) => raw => {
  const [name, value] = raw.split(":")
  const { current, ui } = state
  current[name] = value
  ui.dictTypes = getDictTypes(name, value)
  if (name === "property") {
    current.type = value !== "ts_index" ? "" : "TIMESTAMP"
  }
  setState({ current, ui })
}

const spliceTsIndexPropertyFromOptions = () => {
  return PROPERTY_OPTIONS.filter(
    opt =>
      String(opt.label).toLowerCase() !==
      PROPERTY_OPTION_TS_INDEX.label.toLowerCase()
  )
}

const currentIsTsIndexProperty = ({ state }) => {
  return (
    state.current &&
    String(state.current.property).toLowerCase() ===
      PROPERTY_OPTION_TS_INDEX.label.toLowerCase()
  )
}

const handleAppend = ({ state, intl, setState }) => () => {
  const {
    current: { name, description, type },
    dictionary: { fields },
  } = state
  const errors = []
  if (name.length === 0) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-fields-no-name-error-text",
      })
    )
  }
  if (name.match(/ /g)) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-fields-white-spaces-error-text",
      })
    )
  }
  if (name === "__error") {
    errors.push(
      intl.formatMessage({
        id: "dictionary-fields-reserved-names-error-text",
      })
    )
  }
  if (description.length === 0) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-fields-no-description-error-text",
      })
    )
  }
  let propertyOptions = PROPERTY_OPTIONS
  if (type.length === 0) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-fields-no-type-error-text",
      })
    )
  } else if (currentIsTsIndexProperty({ state })) {
    propertyOptions = spliceTsIndexPropertyFromOptions()
  }
  if (fields.some(field => field.name === name)) {
    errors.push(
      intl.formatMessage({
        id: "dictionary-fields-already-exists-error-text",
      })
    )
  }
  if (errors.length > 0) {
    const hideMessage = message.error({
      content: (
        <MessageErrors
          errors={errors}
          onClose={() => hideMessage()}
          intl={intl}
        />
      ),
      duration: 0,
    })
  } else {
    fields.push({
      key: uuidV4(),
      name: state.current.name.trim(),
      description: state.current.description.trim(),
      additionalInfo: state.current.additionalInfo.trim(),
      type: state.current.type,
      property: state.current.property === "none" ? "" : state.current.property,
    })
    setState({
      dictionary: {
        ...state.dictionary,
        fields,
      },
      current: {
        name: "",
        description: "",
        additionalInfo: "",
        type: "",
        property: "",
      },
      ui: {
        ...state.ui,
        propertyOptions,
      },
    })
  }
}

const download = (filename, text) => {
  const element = document.createElement("a")
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  )
  element.setAttribute("download", filename)
  element.style.display = "none"
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

const handleDownloadTemplate = ({ intl }) => () => {
  let templateString = JSON.stringify(template, null, 2)
  const toReplace = {
    FIELD_NAME: "template_field_name",
    FIELD_DESCRIPTION: "template_field_description",
    FIELD_ADD_INFO: "template_field_additionalInfo",
    FIELD_TYPE: "template_field_type",
    FIELD_PROPERTY: "template_field_property",
  }
  Object.keys(toReplace).forEach(key => {
    templateString = templateString
      .split(key)
      .join(intl.formatMessage({ id: toReplace[key] }))
  })
  download("template.json", templateString)
}

const onLoadDictionaryFile = ({ state, setState, intl }) => e => {
  const contents = e.target.result
  try {
    const { fields } = JSON.parse(contents)
    const dictionary = {}
    const errors = utils.getFieldsErrors({ fields, intl })
    if (errors.length === 0) {
      dictionary.fields = fields.map(field => ({ ...field, key: uuidV4() }))
      setState({
        dictionary: {
          ...state.dictionary,
          ...dictionary,
        },
      })
    } else {
      const hideMessage = message.error({
        content: (
          <MessageErrors
            errors={errors}
            onClose={() => hideMessage()}
            intl={intl}
          />
        ),
        duration: 0,
      })
    }
  } catch (e) {
    const hideMessage = message.error({
      content: (
        <MessageErrors
          errors={[intl.formatMessage({ id: "dictionary-invalid-json-error" })]}
          onClose={() => hideMessage()}
          intl={intl}
        />
      ),
      duration: 0,
    })
  }
}

const readDictionaryFile = ({ state, setState, intl }) => evt => {
  const f = evt.target.files[0]
  if (f) {
    const r = new FileReader()
    r.onload = onLoadDictionaryFile({ state, setState, intl })
    r.readAsText(f)
  }
  document.getElementById("uploadDictionaryFile").value = ""
}

const handleUploadDictionary = () => {
  document.getElementById("uploadDictionaryFile").click()
}

const utils = {
  handleSubmitEmitter,
  getFieldsErrors,
  PROPERTY_OPTIONS,
  fieldsTableColumns,
  PROPERTY_OPTION_TS_INDEX,
  handleChange,
  handleCurrentChange,
  handleSelectChange,
  handleAppend,
  handleUploadDictionary,
  readDictionaryFile,
  handleDownloadTemplate,
}

export default utils
