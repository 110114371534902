import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import { Col, Form, Row } from "antd"
import Template from "../../layouts/base"
import withAuth from "../../components/withAuthHoc"
import NewDictionaryForm from "../../components/dictionary/newDictionaryForm"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import useStateObject from "../../hooks/useStateObject"
import formUtils from "../../utils/dictionary/form-utils"

const DictionaryForm = ({ location, form }) => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    buttonsLoading: false,
    versions:
      location.state && location.state.versions ? location.state.versions : [],
    dictionary:
      location.state && location.state.dictionary
        ? formUtils.cloneDictionary(location.state.dictionary)
        : formUtils.initEmptyDictionary(),
    isVersionForm: location.state ? location.state.isVersionForm : false,
  })

  const { getFieldDecorator } = form

  return (
    <Template selected={["dictionary", "dictionary-form"]}>
      <CustomBreadcrumb
        crumbs={[
          {
            label: intl.formatMessage({ id: "menu-dictionary-management" }),
            link: "/dictionary",
          },
          state.isVersionForm
            ? intl.formatMessage({ id: "dictionary-version-new-text" })
            : intl.formatMessage({ id: "dictionary-form-create-text-button" }),
        ]}
      />

      <Row>
        <Col className="intro">
          <h1>
            {state.isVersionForm
              ? intl.formatMessage({ id: "dictionary-version-new-text" })
              : intl.formatMessage({ id: "dictionary-new-data-text" })}
          </h1>
        </Col>
      </Row>

      <div className="content">
        <NewDictionaryForm
          dictionary={state.dictionary}
          isVersionForm={state.isVersionForm}
          getFieldDecorator={getFieldDecorator}
          onSubmitForm={dictionary =>
            formUtils.handleSubmit({ dictionary, setState, state, intl })
          }
          buttonsLoading={state.buttonsLoading}
          onCancel={formUtils.cancelForm({ state })}
        />
      </div>
    </Template>
  )
}

DictionaryForm.propTypes = {
  location: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default Form.create({ name: "normal_login" })(withAuth(DictionaryForm))
